import { ReactNode } from 'react';
import styled from 'styled-components';
const SkygramEmbed = styled.div`
  z-index: 100000;
  position: fixed;
  bottom: 80px;
  right: 80px;
`;

export default function ChatRequestContainer({
  children,
  containerRef,
}: {
  children: ReactNode;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  containerRef: any;
}) {
  return (
    <SkygramEmbed
      className="skygram-embed"
      data-bs-toggle="tooltip"
      data-bs-placement="top"
      title="Talk with us"
      ref={containerRef}
    >
      {children}
    </SkygramEmbed>
  );
}
