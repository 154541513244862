import React, { useEffect, useState } from 'react';

interface CountdownProps {
  initialCountdown: number;
  onCountdownEnd: () => void;
  interval: NodeJS.Timeout | undefined;
  className?: string;
}

export default function CountDown({
  initialCountdown,
  onCountdownEnd,
  interval,
  className,
}: CountdownProps) {
  const [countdown, setCountdown] = useState(initialCountdown);

  useEffect(() => {
    if (countdown > 0) {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      interval = setInterval(() => {
        setCountdown((prevCount) => prevCount - 1);
      }, 1000);
    } else {
      clearInterval(interval);
      onCountdownEnd();
    }

    return () => {
      if (interval) clearInterval(interval);
    };
  }, [countdown, onCountdownEnd]);

  return <span className={className}>{`(${countdown})`}</span>;
}
