import React, {
  useState,
  useEffect,
  useRef,
  forwardRef,
  useImperativeHandle,
  useCallback,
} from 'react';
import { clsx } from 'clsx';
import { IoIosResize } from 'react-icons/io';
import styled from 'styled-components';

interface DraggableProps {
  open: boolean;
  resizable?: boolean;
  anchorRef: React.RefObject<HTMLElement>;
  className?: string;
  style?: React.CSSProperties;
  children?: React.ReactNode;
}

export type DraggablePopoverRef = {
  resetToAnchor: () => void;
};

const DraggablePopoverRoot = styled.div<{ open: boolean }>`
  display: ${(props) => (props.open ? 'block' : 'none')};
`;

const DraggablePopoverContent = styled.div<{
  dragging: boolean;
  resizing: boolean;
  x: number;
  y: number;
  width: number;
  height: number;
}>`
  position: fixed;
  left: ${(props) => props.x}px;
  top: ${(props) => props.y}px;
  width: ${(props) => props.width}px;
  cursor: ${(props) =>
    props.dragging ? 'grabbing' : props.resizing ? 'nwse-resize' : 'grab'};
  touch-action: none;
`;

const ResizeIconContainer = styled.div`
  position: absolute;
  bottom: -10px;
  right: -10px;
  padding: 8px;
  cursor: nwse-resize;
`;

const ResizeIcon = styled.div<{ resizable: boolean }>`
  display: ${(props) => (props.resizable ? 'block' : 'none')};
  transform: rotate(90deg);
`;

function isInteractionFromInputOrButton(
  event: React.MouseEvent | React.TouchEvent
): boolean {
  let target: EventTarget | null;

  if (event.nativeEvent instanceof MouseEvent) {
    target = event.nativeEvent.target;
  } else if (event instanceof TouchEvent) {
    target = event.nativeEvent.target;
  } else {
    return false;
  }

  if (target instanceof Element) {
    return (
      target.tagName === 'BUTTON' ||
      target.tagName === 'INPUT' ||
      target.closest('.chat-bubble-sg > .message-content-sg') !== null
    );
  }

  return false;
}
export const DraggablePopover = forwardRef<DraggablePopoverRef, DraggableProps>(
  (
    { open, anchorRef, className, style, resizable = true, children },
    draggableRef
  ) => {
    const [pos, setPos] = useState({ x: 0, y: 0 });
    const [dragging, setDragging] = useState(false);
    const [rel, setRel] = useState<{ x: number; y: number } | null>(null);
    const ref = useRef<HTMLDivElement | null>(null);
    const [resizing, setResizing] = useState(false);
    const [size, setSize] = useState({ width: 400, height: 207 });

    const resetToAnchor = useCallback(() => {
      if (!anchorRef.current || !ref.current) {
        return;
      }

      const anchorEl = anchorRef.current;
      const { offsetWidth, offsetHeight } = ref.current;

      const newPos = {
        x: anchorEl.getBoundingClientRect().left - offsetWidth + 30,
        y: anchorEl.getBoundingClientRect().top - offsetHeight + 30,
      };
      setPos(newPos);
    }, [anchorRef]);

    useImperativeHandle(
      draggableRef,
      () => ({
        resetToAnchor: () => {
          resetToAnchor();
        },
      }),
      [resetToAnchor]
    );

    useEffect(() => {
      if (!open || !ref.current) return;

      const containerEl = ref.current;
      const updatePosition = (e?: Event, onContainerResize = false) => {
        if (!anchorRef.current || !containerEl) return;

        const anchorEl = anchorRef.current;
        const { offsetWidth, offsetHeight } = containerEl;

        setPos((curr) => {
          if (onContainerResize) {
            if (
              curr.x + offsetWidth < window.innerWidth &&
              curr.y + offsetHeight < window.innerHeight
            ) {
              return curr;
            }
          }
          return {
            x: anchorEl.getBoundingClientRect().left - offsetWidth + 30,
            y: anchorEl.getBoundingClientRect().top - offsetHeight + 30,
          };
        });
      };

      window.addEventListener('resize', updatePosition);
      const resizeObserver = new ResizeObserver(() =>
        updatePosition(undefined, true)
      );
      resizeObserver.observe(containerEl);

      updatePosition();

      return () => {
        resizeObserver.disconnect();
        window.removeEventListener('resize', updatePosition);
      };
    }, [anchorRef, open]);

    useEffect(() => {
      const onMove = (clientX: number, clientY: number) => {
        if (dragging || resizing) {
          if (ref.current) {
            const { offsetWidth, offsetHeight } = ref.current;
            if (dragging && rel) {
              const newX = clientX - rel.x;
              const newY = clientY - rel.y;
              const bounds = {
                left: 0,
                top: 0,
                right: window.innerWidth,
                bottom: window.innerHeight,
              };

              setPos({
                x: Math.max(
                  bounds.left,
                  Math.min(bounds.right - offsetWidth, newX)
                ),
                y: Math.max(
                  bounds.top,
                  Math.min(bounds.bottom - offsetHeight, newY)
                ),
              });
            } else if (resizing) {
              const newWidth = Math.max(
                100,
                clientX - ref.current.getBoundingClientRect().left
              );
              const newHeight = Math.max(
                100,
                clientY - ref.current.getBoundingClientRect().top
              );

              setSize({ width: newWidth, height: newHeight });
            }
          }
        }
      };

      const onMouseMove = (e: MouseEvent) => {
        onMove(e.clientX, e.clientY);
        e.stopPropagation();
        e.preventDefault();
      };

      const onTouchMove = (e: TouchEvent) => {
        onMove(e.touches[0].clientX, e.touches[0].clientY);
        e.stopPropagation();
        e.preventDefault();
      };

      const onMouseUp = (e: MouseEvent) => {
        setDragging(false);
        setResizing(false);
        e.stopPropagation();
        e.preventDefault();
      };

      const onTouchEnd = (e: TouchEvent) => {
        setDragging(false);
        setResizing(false);
        e.stopPropagation();
        e.preventDefault();
      };

      if (dragging || resizing) {
        // Update the condition to include resizing
        document.addEventListener('mousemove', onMouseMove);
        document.addEventListener('mouseup', onMouseUp);
        document.addEventListener('touchmove', onTouchMove);
        document.addEventListener('touchend', onTouchEnd);
      } else {
        document.removeEventListener('mousemove', onMouseMove);
        document.removeEventListener('mouseup', onMouseUp);
        document.removeEventListener('touchmove', onTouchMove);
        document.removeEventListener('touchend', onTouchEnd);
      }

      return () => {
        document.removeEventListener('mousemove', onMouseMove);
        document.removeEventListener('mouseup', onMouseUp);
        document.removeEventListener('touchmove', onTouchMove);
        document.removeEventListener('touchend', onTouchEnd);
      };
    }, [dragging, resizing, rel]);

    const onMouseDown = (e: React.MouseEvent) => {
      if (isInteractionFromInputOrButton(e)) {
        e.stopPropagation();
        return;
      }
      if (e.button !== 0) return;
      if (ref.current) {
        const { left, top } = ref.current.getBoundingClientRect();
        setDragging(true);
        setRel({
          x: e.clientX - left,
          y: e.clientY - top,
        });
      }
      e.stopPropagation();
      e.preventDefault();
    };

    const onTouchStart = (e: React.TouchEvent) => {
      if (isInteractionFromInputOrButton(e)) {
        e.stopPropagation();
        return;
      }

      if (ref.current) {
        const { left, top } = ref.current.getBoundingClientRect();
        setDragging(true);
        setRel({
          x: e.touches[0].clientX - left,
          y: e.touches[0].clientY - top,
        });
      }
      e.stopPropagation();
      e.preventDefault();
    };

    const onResizeMouseDown = (e: React.MouseEvent) => {
      e.stopPropagation();
      e.preventDefault();
      if (e.button !== 0) return;
      setResizing(true);
    };

    const onResizeTouchStart = (e: React.TouchEvent) => {
      e.stopPropagation();
      e.preventDefault();
      setResizing(true);
    };

    return (
      <DraggablePopoverRoot
        open={open}
        className={clsx('draggable-popover-root', {
          'hide-sg': !open,
        })}
      >
        <DraggablePopoverContent
          ref={ref}
          onMouseDown={onMouseDown}
          onTouchStart={onTouchStart}
          dragging={dragging}
          className={clsx('draggable-popover-content', className)}
          resizing={resizing}
          x={pos.x}
          y={pos.y}
          width={size.width}
          height={size.height}
          style={style}
        >
          {children}
          <ResizeIconContainer
            onMouseDown={onResizeMouseDown}
            onTouchStart={onResizeTouchStart}
          >
            <ResizeIcon
              resizable={resizable}
              className={clsx({
                'd-none': !resizable,
              })}
            >
              <IoIosResize size={20} color="black" />
            </ResizeIcon>
          </ResizeIconContainer>
        </DraggablePopoverContent>
      </DraggablePopoverRoot>
    );
  }
);
