import styled from 'styled-components';
import { IoMdSend } from 'react-icons/io';
import { ChatMessage, ReceivedChatMessage } from '@livekit/components-react';

const StyledButton = styled.button`
  position: absolute;
  right: 0;
  border: 0;
  background: transparent;
  cursor: pointer;
  padding: 0;
  z-index: 1;
  &:disabled {
    cursor: not-allowed;
  }
`;

const StyledIcon = styled(IoMdSend)`
  color: black;
  margin-right: 0.5rem;
  cursor: pointer;
`;

const SendButton = ({
  chat,
  message,
  sendMessage,
}: {
  chat: {
    send: ((message: string) => Promise<ChatMessage>) | undefined;
    update:
      | ((message: string, messageId: string) => Promise<ChatMessage>)
      | undefined;
    chatMessages: ReceivedChatMessage[];
    isSending: boolean;
  };
  message: string;
  sendMessage: (data: ReceivedChatMessage[]) => void;
}) => {
  const isDisabled = chat.isSending || !message.trim().length;

  return (
    <StyledButton
      disabled={isDisabled}
      onClick={() => sendMessage(chat.chatMessages)}
    >
      <StyledIcon />
    </StyledButton>
  );
};

export default SendButton;
