import styled from 'styled-components';
import moment from 'moment';
import { Room } from 'livekit-client';
import { ReceivedChatMessage } from '@livekit/components-react';

interface ChatBubbleProps {
  isMyMessage: boolean;
}

const ChatBubble = styled.div<ChatBubbleProps>`
  margin-bottom: 0.75rem;
  max-width: 70%;
  clear: both;
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
  position: relative;
  word-wrap: break-word;
  white-space: pre-wrap;
  cursor: text;
  background-color: ${({ isMyMessage }) =>
    isMyMessage ? '#f5f8fa' : '#f8f9fa'};
  color: ${({ isMyMessage }) => (isMyMessage ? 'rgb(26, 26, 26)' : 'black')};
  float: ${({ isMyMessage }) => (isMyMessage ? 'right' : 'left')};
  border-top-right-radius: ${({ isMyMessage }) =>
    isMyMessage ? '0' : '0.5rem'};
  border-top-left-radius: ${({ isMyMessage }) =>
    !isMyMessage ? '0' : '0.5rem'};
  text-align: ${({ isMyMessage }) => (isMyMessage ? 'right' : 'left')};
  width: 100%;
`;

const MessageContent = styled.div`
  display: flex;
  flex-direction: column;
`;

const MessageSender = styled.span`
  font-size: 0.5rem;
  font-weight: 500;
  margin-bottom: 0.25rem;
`;

const MessageText = styled.div`
  font-size: 1rem;
  margin-bottom: 0.25rem;
  text-align: start;
`;

const MessageTimestamp = styled.span`
  font-size: 0.4rem;
  align-self: flex-end;
`;

export default function ChatMessageList({
  item,
  room,
}: {
  item: ReceivedChatMessage;
  room: Room;
}) {
  const isMyMessage = room?.localParticipant?.identity === item.from?.identity;
  return (
    <ChatBubble isMyMessage={isMyMessage} key={item.id}>
      <MessageContent>
        <MessageSender>
          {isMyMessage ? room?.localParticipant?.identity : item.from?.identity}
        </MessageSender>
        <MessageText>{item.message}</MessageText>
        <MessageTimestamp>
          {moment(item.timestamp).format('HH:mm')}
        </MessageTimestamp>
      </MessageContent>
    </ChatBubble>
  );
}
