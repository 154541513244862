import 'bootstrap/dist/css/bootstrap.min.css';
import { IoLogoXing } from 'react-icons/io';
import { Fragment } from 'react/jsx-runtime';
import './App.css';
import ChatBot from './chatBot/chatBot';
import { dummyObject } from './constant';
import generateUniqueId from 'generate-unique-id';
import { useEffect } from 'react';

const newMetaId = generateUniqueId({
  length: 10,
  useLetters: true,
  useNumbers: true,
});
const existingMetaId = sessionStorage.getItem('metaId');

function App() {
  useEffect(() => {
    if (!existingMetaId) {
      sessionStorage.setItem('metaId', newMetaId);
    }
  }, []);

  const apiKey =
    sessionStorage.getItem('apiKey') ||
    '32450fcecbb6111978b944e56401bbbaa9eb109e';
  const id = existingMetaId || newMetaId || '';
  const name = sessionStorage.getItem('name') || '';
  const email = sessionStorage.getItem('email') || '';

  return (
    <Fragment>
      <div id="custom-cursor-sg" />
      <div className="container">
        <div className="py-5 text-center">
          <div className="d-block mx-auto mb-4">
            <span>
              <IoLogoXing size={60} />
            </span>
          </div>
          <h2>Checkout form</h2>
          <p className="lead">
            Below is an example form built entirely with Bootstrap's form
            controls. Each required form group has a validation state that can
            be triggered by attempting to submit the form without completing it.
          </p>
        </div>

        <div className="row">
          <div className="order-md-2 mb-4 col-md-4">
            <h4 className="d-flex justify-content-between align-items-center mb-3">
              <span className="text-muted">Your cart</span>
              <span className="badge badge-pill badge-secondary">3</span>
            </h4>
            <ul className="mb-3 list-group">
              <li className="d-flex justify-content-between lh-condensed list-group-item">
                <div>
                  <h6 className="my-0">Product name</h6>
                  <small className="text-muted">Brief description</small>
                </div>
                <span className="text-muted">$12</span>
              </li>
              <li className="d-flex justify-content-between lh-condensed list-group-item">
                <div>
                  <h6 className="my-0">Second product</h6>
                  <small className="text-muted">Brief description</small>
                </div>
                <span className="text-muted">$8</span>
              </li>
              <li className="d-flex justify-content-between lh-condensed list-group-item">
                <div>
                  <h6 className="my-0">Third item</h6>
                  <small className="text-muted">Brief description</small>
                </div>
                <span className="text-muted">$5</span>
              </li>
              <li className="d-flex justify-content-between bg-light list-group-item">
                <div className="text-success">
                  <h6 className="my-0">Promo code</h6>
                  <small>EXAMPLECODE</small>
                </div>
                <span className="text-success">-$5</span>
              </li>
              <li className="d-flex justify-content-between list-group-item">
                <span>Total (USD)</span>
                <strong>$20</strong>
              </li>
            </ul>

            <form className="p-2 card">
              <div className="input-group">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Promo code"
                  disabled
                />
                <div className="input-group-append">
                  <button type="button" className="btn btn-secondary">
                    Redeem
                  </button>
                </div>
              </div>
            </form>
          </div>
          <div className="order-md-1 col-md-8">
            <h4 className="mb-3">Billing address</h4>
            <form className="needs-validation" noValidate>
              <div className="row">
                <div className="mb-3 col-md-6">
                  <label htmlFor="firstName">First name</label>
                  <input
                    type="text"
                    className="form-control"
                    id="firstName"
                    placeholder=""
                    required
                    onChange={(e) => {
                      console.log('onchange value log', e.target.value);
                      console.log('dummyObject', dummyObject);
                    }}
                  />
                  <div className="invalid-feedback">
                    Valid first name is required.
                  </div>
                </div>
                <div className="mb-3 col-md-6">
                  <label htmlFor="lastName">Last name</label>
                  <input
                    type="text"
                    className="form-control"
                    id="lastName"
                    placeholder=""
                    required
                    onChange={(e) => {
                      console.info('onchange value info', e.target.value);
                    }}
                  />
                  <div className="invalid-feedback">
                    Valid last name is required.
                  </div>
                </div>
              </div>

              <div className="mb-3">
                <label htmlFor="username">Username</label>
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span className="input-group-text">@</span>
                  </div>
                  <input
                    type="text"
                    className="form-control"
                    id="username"
                    placeholder="Username"
                    required
                    onChange={(e) => {
                      console.warn('onchange value warn', e.target.value);
                    }}
                  />
                  <div className="invalid-feedback" style={{ width: '100%' }}>
                    Your username is required.
                  </div>
                </div>
              </div>

              <div className="mb-3">
                <label htmlFor="email">
                  Email <span className="text-muted">(Optional)</span>
                </label>
                <input
                  type="email"
                  className="form-control"
                  id="email"
                  placeholder="you@example.com"
                  onChange={(e) => {
                    console.error('onchange value error', e.target.value);
                  }}
                />
                <div className="invalid-feedback">
                  Please enter a valid email address for shipping updates.
                </div>
              </div>

              <div className="mb-3">
                <label htmlFor="address">Address</label>
                <input
                  type="text"
                  className="form-control"
                  id="address"
                  placeholder="1234 Main St"
                  required
                />
                <div className="invalid-feedback">
                  Please enter your shipping address.
                </div>
              </div>

              <div className="mb-3">
                <label htmlFor="address2">
                  Address 2 <span className="text-muted">(Optional)</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="address2"
                  placeholder="Apartment or suite"
                />
              </div>

              <div className="row">
                <div className="mb-3 col-md-5">
                  <label htmlFor="country">Country</label>
                  <select
                    className="d-block custom-select w-100"
                    id="country"
                    required
                  >
                    <option value="">Choose...</option>
                    <option>United States</option>
                  </select>
                  <div className="invalid-feedback">
                    Please select a valid country.
                  </div>
                </div>
                <div className="mb-3 col-md-4">
                  <label htmlFor="state">State</label>
                  <select
                    className="d-block custom-select w-100"
                    id="state"
                    required
                  >
                    <option value="">Choose...</option>
                    <option>California</option>
                  </select>
                  <div className="invalid-feedback">
                    Please provide a valid state.
                  </div>
                </div>
                <div className="mb-3 col-md-3">
                  <label htmlFor="zip">Zip</label>
                  <input
                    type="text"
                    className="form-control"
                    id="zip"
                    placeholder=""
                    required
                  />
                  <div className="invalid-feedback">Zip code required.</div>
                </div>
              </div>
              <hr className="mb-4" />
              <div className="custom-checkbox custom-control">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  id="same-address"
                />
                <label className="custom-control-label" htmlFor="same-address">
                  Shipping address is the same as my billing address
                </label>
              </div>
              <div className="custom-checkbox custom-control">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  id="save-info"
                />
                <label className="custom-control-label" htmlFor="save-info">
                  Save this information for next time
                </label>
              </div>
              <hr className="mb-4" />

              <h4 className="mb-3">Payment</h4>

              <div className="d-block my-3">
                <div className="custom-control custom-radio">
                  <input
                    id="credit"
                    name="paymentMethod"
                    type="radio"
                    className="custom-control-input"
                    readOnly
                    checked={true}
                    required
                  />
                  <label className="custom-control-label" htmlFor="credit">
                    Credit card
                  </label>
                </div>
                <div className="custom-control custom-radio">
                  <input
                    id="debit"
                    name="paymentMethod"
                    type="radio"
                    className="custom-control-input"
                    required
                  />
                  <label className="custom-control-label" htmlFor="debit">
                    Debit card
                  </label>
                </div>
                <div className="custom-control custom-radio">
                  <input
                    id="paypal"
                    name="paymentMethod"
                    type="radio"
                    className="custom-control-input"
                    required
                  />
                  <label className="custom-control-label" htmlFor="paypal">
                    PayPal
                  </label>
                </div>
              </div>
              <div className="row">
                <div className="mb-3 col-md-6">
                  <label htmlFor="cc-name">Name on card</label>
                  <input
                    type="text"
                    className="form-control"
                    id="cc-name"
                    placeholder=""
                    required
                  />
                  <small className="text-muted">
                    Full name as displayed on card
                  </small>
                  <div className="invalid-feedback">
                    Name on card is required
                  </div>
                </div>
                <div className="mb-3 cc-number col-md-6">
                  <label htmlFor="cc-number">Credit card number</label>
                  <input
                    type="text"
                    className="form-control skygram-mask"
                    id="cc-number"
                    placeholder=""
                    required
                  />
                  <div className="invalid-feedback">
                    Credit card number is required
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="mb-3 cc-expiration col-md-3">
                  <label htmlFor="cc-expiration">Expiration</label>
                  <input
                    type="text"
                    className="form-control skygram-mask"
                    id="cc-expiration"
                    placeholder=""
                    required
                  />
                  <div className="invalid-feedback">
                    Expiration date required
                  </div>
                </div>
                <div className="mb-3 cc-cvv col-md-3">
                  <label htmlFor="cc-cvv">CVV</label>
                  <input
                    type="text"
                    className="form-control skygram-mask"
                    id="cc-cvv"
                    placeholder=""
                    required
                  />
                  <div className="invalid-feedback">Security code required</div>
                </div>
              </div>
              <hr className="mb-4" />
              <button
                className="btn-block btn btn-lg btn-primary"
                type="button"
              >
                Continue to checkout
              </button>
            </form>
          </div>
        </div>
        <footer className="my-5 pt-5 text-center text-muted text-small">
          You can click the replay button to replay your actions.
        </footer>
      </div>
      <ChatBot
        apiKey={apiKey}
        meta={{
          id: id,
          name: name,
          email: email,
        }}
      />
    </Fragment>
  );
}
export default App;
