/* eslint-disable @typescript-eslint/no-explicit-any */
import { LiveKitRoom, useRemoteParticipants } from '@livekit/components-react';
import generateUniqueId from 'generate-unique-id';
import {
  ConnectionState,
  DataPacket_Kind,
  RemoteParticipant,
  Room,
  RoomEvent,
} from 'livekit-client';
import { useCallback, useEffect, useRef, useState } from 'react';
import { HiPhoneMissedCall } from 'react-icons/hi';
import { MdOutlineTimer } from 'react-icons/md';
import {
  REACT_APP_CALL_JOIN_TIMEOUT_SEC,
  REACT_APP_LIVEKIT_SOCKET_URL,
} from '../constant';
import { LiveKitService } from '../service';
import { MetaProps } from '../types';
import { connectAgent } from '../util';
import { encrypt } from '../util/enCryptAndDeCrypt';
import { IAccount } from '../util/interface/IAccount.interface';
import CountDown from './CountDown';
import { DraggablePopoverRef } from './DraggablePopover';
import PrivateRoom from './privateRoom';
import {
  ActionButton,
  AgentConnectTitle,
  ButtonWrapper,
  CallingHeader,
  CobrowsingImage,
  CobrowsingInfo,
  CobrowsingText,
  ColumnContainer,
  Container,
  ErrorText,
  FlexColumnDiv,
  FlexContainer,
  JoinContainer,
  Logo,
  LogoContainer,
  LogoText,
  PublicRoomContainer,
  StyledButton,
  Subtitle,
  SuccessText,
  Title,
  WarningText,
} from './styleComponent/ChatRequest/ChatBotPopover';
import ChatDraggablePopover from './styleComponent/ChatRequest/ChatDraggablePopover';
import FloatingChatButton from './styleComponent/ChatRequest/FloatingButton';
import ChatRequestContainer from './styleComponent/ChatRequest/SkygramEmbed';

const socketUrl = REACT_APP_LIVEKIT_SOCKET_URL;
export default function ChatRequest({
  room,
  meta,
  account,
}: {
  room: Room;
  meta?: MetaProps;
  account: IAccount | undefined;
}) {
  const [updatedMeta, setMeta] = useState<string | undefined>();
  const timeoutRef = useRef<ReturnType<typeof setTimeout> | undefined>();
  const userLatitude = useRef<number | null>(null);
  const userLongitude = useRef<number | null>(null);
  const [isActive, setActive] = useState(false);
  const [actionType, setActionType] = useState<
    'Reject' | 'Join' | 'Request' | 'Accept' | 'Cancel' | undefined
  >();
  const [showOverlay, setShowOverlay] = useState<boolean>(false);
  const [isConnectAgent, setIsConnectAgent] = useState<boolean>(true);
  const [privateToken, setPrivateToken] = useState<string | undefined>();
  const [privateRoomId, setPrivateRoomId] = useState<string | undefined>();
  const [isPrivateRoomJoined, setPrivateRoomIdJoined] =
    useState<boolean>(false);
  const [senderName, setSenderName] = useState<string | undefined>();
  const [error, setError] = useState<string | undefined>();
  const floatingChatBtnRef = useRef<HTMLButtonElement>(null);
  const draggablePopoverRef = useRef<DraggablePopoverRef>(null);
  let rejectTimeout: NodeJS.Timeout | undefined;
  const callIntervalRef = useRef<NodeJS.Timeout | null>(null);
  const [isCallActive, setIsCallActive] = useState(false);
  const isCallActiveRef = useRef(false);
  const connectingAgentName = useRef<string | undefined>(undefined);
  const JoinTimeoutRef = useRef<number | undefined>();
  const [isOfflineStringPublicRoom, setIsOfflineStringPublicRoom] =
    useState<string>('');
  const containerDivRef = useRef<HTMLDivElement>(null);
  const audioRef = useRef<HTMLAudioElement>(null);
  const noOfAgentsAttemptedRef = useRef<number>(0);
  const [togglePrivateRoom, setTogglePrivateRoom] = useState<boolean>(true);
  const roomRejoined = useRef<boolean>(false);
  const privateRoomjoinedRef = useRef<boolean>(false);
  const privateRoomRejoiningRef = useRef<boolean>(false);
  const remoteParticipants: RemoteParticipant[] = useRemoteParticipants({
    updateOnlyOn: [
      RoomEvent.ParticipantConnected,
      RoomEvent.ParticipantDisconnected,
      RoomEvent.Connected,
      RoomEvent.Disconnected,
      RoomEvent.Reconnected,
    ],
    room,
  });
  useEffect(() => {
    const getPrivateRoomId = sessionStorage.getItem('privateRoomId');
    if (getPrivateRoomId && isActive) {
      setShowOverlay(true);
      setPrivateRoomIdJoined(true);
      setPrivateRoomId(getPrivateRoomId);
      joinRoom(getPrivateRoomId);
    }
    const getIncomingSessionId = sessionStorage.getItem('incomingSessionId');
    if (getIncomingSessionId && isActive) {
      setShowOverlay(true);
      setPrivateRoomId(getIncomingSessionId);
      setActionType('Join');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isActive]);

  useEffect(() => {
    if (actionType) {
      setShowOverlay(true);
      if (actionType === 'Join') {
        audioRef.current?.play().catch((error) => {
          console.error('Audio play failed:', error);
        });
      }
    }
    if (actionType === 'Cancel') {
      audioRef.current?.pause();
      setActionType(undefined);
      setError('Agent canceled the call');
      sessionStorage.removeItem('incomingSessionId');
    }
    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      audioRef.current?.pause();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actionType]);

  const sendMessage = useCallback(
    async (
      topic: 'Reject' | 'Join' | 'Request' | 'Cancel' | 'End' | 'Busy',
      name?: string,
      sessionId?: string,
      roomName?: string,
      extraMessage?: string | number
    ) => {
      const currentName = name || senderName || '';
      if (currentName) {
        console.info(
          'sending topic:',
          topic,
          'message:',
          `${sessionId || privateRoomId || room?.name}-${
            room.localParticipant.identity
          }`
        );
        await LiveKitService.sendMessage({
          roomName: roomName || privateRoomId || room?.name,
          message: `${sessionId || privateRoomId || room?.name}-${
            room.localParticipant.identity
          }`,
          destinationIdentities: [currentName],
          topic: topic,
          extraMessage: extraMessage
            ? String(extraMessage)
            : meta?.name
              ? meta?.name
              : meta?.id,
        });
        resetConnectionState(topic === 'Request');
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [privateRoomId, room.localParticipant.identity, room?.name, senderName]
  );

  const handleRejectRequest = useCallback(async () => {
    setIsCallActive(false);
    sessionStorage.removeItem('incomingSessionId');
    setActionType(undefined);
    if (rejectTimeout) {
      clearInterval(rejectTimeout);
    }
    if (JoinTimeoutRef) {
      JoinTimeoutRef.current = undefined;
    }
    await sendMessage('Reject');
    if ((account?.meta?.end_user as any)?.botHide) {
      setShowOverlay(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sendMessage]);

  const getActionTry = (action: string) => {
    switch (true) {
      case action.includes('Reject'):
        return 'Reject';
      case action.includes('Join'):
        return 'Join';
      case action.includes('Accept'):
        return 'Accept';
      case action.includes('Cancel'):
        return 'Cancel';
      default:
        return undefined;
    }
  };

  //decode the recived data
  const handleDataReceived = useCallback(
    (
      payload: Uint8Array,
      participant?: RemoteParticipant,
      kind?: DataPacket_Kind | undefined,
      topic?: string | undefined
    ) => {
      const decoder = new TextDecoder();
      if (topic === 'Error') {
        console.info('received error message:', decoder.decode(payload));
        setError(decoder.decode(payload));
        return;
      }
      const decodedMessage = decoder.decode(payload).split('-');

      let extraMessage: string | undefined;
      if (decodedMessage.length > 2) {
        extraMessage = decodedMessage[decodedMessage.length - 1];
      }
      const privateRoomId = decodedMessage[0];
      const senderName = decodedMessage[1];
      console.info(
        `Decoded: ${decoder.decode(
          payload
        )}, \n Topic: ${topic}, Kind: ${kind}, Participant: ${participant}, Sender: ${senderName}, Extra: ${extraMessage}`
      );

      if (topic === 'Send') {
        if (extraMessage && !isNaN(Number(extraMessage))) {
          JoinTimeoutRef.current = parseInt(extraMessage, 10);
        } else {
          console.info('extraMessage is not a valid number its a string');
        }
      }

      if (privateRoomId && topic === 'Join') {
        if (isCallActive) {
          sendMessage(
            'Busy',
            senderName,
            privateRoomId,
            '',
            noOfAgentsAttemptedRef.current
          );
          sendMessage(
            'Busy',
            senderName,
            privateRoomId,
            privateRoomId,
            noOfAgentsAttemptedRef.current //TODO no of seconds per  to respond from agent
          );
        } else {
          setPrivateRoomId(privateRoomId);
          sessionStorage.setItem('incomingSessionId', privateRoomId);
        }
      }
      setSenderName(senderName);
      topic &&
        topic != 'Send' &&
        !isCallActive &&
        setActionType(getActionTry(topic));
      if (topic === 'Accept') {
        joinRoom(privateRoomId);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [room, isCallActive, noOfAgentsAttemptedRef]
  );

  useEffect(() => {
    room.on(RoomEvent.DataReceived, handleDataReceived);
    return () => {
      room.off(RoomEvent.DataReceived, handleDataReceived);
    };
  }, [handleDataReceived, room]);

  const getAgentName = useCallback(
    async (sessionId: string) => {
      isCallActiveRef.current = false;
      const getAgentResponse = await LiveKitService.connectWithAgent({
        sessionId: sessionId,
        roomName: room.name,
      });

      if (!getAgentResponse?.success) {
        if (!privateRoomjoinedRef.current) {
          setError(getAgentResponse?.message);
          setIsCallActive(false);
          setActionType(undefined);
          setIsConnectAgent(true);
          noOfAgentsAttemptedRef.current = 0;
        }
        if (callIntervalRef.current) {
          clearInterval(callIntervalRef.current);
        }
      } else {
        const agentAvailable = remoteParticipants.some((participant) =>
          participant.identity.includes(getAgentResponse?.data?.agentName)
        );

        if (!agentAvailable) {
          getAgentName(sessionId);
        } else {
          noOfAgentsAttemptedRef.current = noOfAgentsAttemptedRef?.current + 1;
          connectingAgentName.current = getAgentResponse?.data?.agentName;
          await sendMessage(
            'Request',
            `${getAgentResponse?.data?.agentName}(Agent)`,
            sessionId
          );
          isCallActiveRef.current = true;
          setIsCallActive(true);
        }
      }
    },
    [room.name, sendMessage, remoteParticipants]
  );

  const sendRequest = useCallback(async () => {
    setIsConnectAgent(false);
    if (updatedMeta && account && meta) {
      const sessionCreationPayload = {
        accountId: account?.id,
        customer_uuid: meta?.id || room.localParticipant.identity,
        customer_meta: updatedMeta,
        customer_name: meta?.name || '',
        customer_email: meta?.email || '',
        call_type: 'INCOMING',
        can_replay: true,
      };

      const sessionRes = await LiveKitService.createEnduserSession(
        sessionCreationPayload
      );
      if (sessionRes?.id) {
        getAgentName(sessionRes?.id);
        callIntervalRef.current = setInterval(() => {
          if (isCallActiveRef.current) {
            getAgentName(sessionRes.id);
          }
        }, 10000);
      } else {
        setError(sessionRes?.message);
      }
    }
  }, [
    account,
    getAgentName,
    meta,
    room.localParticipant.identity,
    updatedMeta,
  ]);

  const resetConnectionState = (isRequest?: boolean) => {
    if (!isRequest) {
      setIsConnectAgent(true);
    }
    if (actionType !== 'Join') {
      setActionType(undefined);
      if (rejectTimeout) {
        clearInterval(rejectTimeout);
      }
    }
    setError(undefined);
    setPrivateRoomId(undefined);
  };
  const endCall = useCallback(async () => {
    resetPublicRoom();
    await sendMessage('Cancel', `${connectingAgentName.current}(Agent)`);
    setIsConnectAgent(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sendMessage]);

  const onGeoLocationSuccess = useCallback((position: GeolocationPosition) => {
    const { latitude, longitude } = position.coords;
    userLatitude.current = latitude;
    userLongitude.current = longitude;
  }, []);

  const onGeoLocationError = useCallback(() => {
    userLatitude.current = null;
    userLongitude.current = null;
  }, []);

  const getLocation = useCallback(() => {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition(
        onGeoLocationSuccess,
        onGeoLocationError
      );
    } else {
      console.error('Geo location not available');
    }
  }, [onGeoLocationError, onGeoLocationSuccess]);

  useEffect(() => {
    getLocation();
  }, [getLocation]);

  const getParticipant = useCallback(
    (identity: string) => {
      return room.getParticipantByIdentity(identity);
    },
    [room]
  );

  function getBrowserEngine() {
    const userAgent = navigator.userAgent;

    if (userAgent.includes('Chrome') || userAgent.includes('Edg/')) {
      return 'Blink'; // Chrome, Edge, and Chromium-based browsers use Blink
    } else if (userAgent.includes('Firefox')) {
      return 'Gecko'; // Firefox uses Gecko
    } else if (userAgent.includes('Safari') && !userAgent.includes('Chrome')) {
      return 'WebKit'; // Safari uses WebKit
    } else if (userAgent.includes('Trident') || userAgent.includes('MSIE')) {
      return 'Trident'; // Older versions of IE use Trident
    } else {
      return 'Unknown';
    }
  }

  const updateMetaData = useCallback(async () => {
    const embedId = room.localParticipant.identity;
    const res = await LiveKitService.getMetaDetailsFormMachine({
      lat: (userLatitude.current || 0).toString(),
      lon: (userLongitude.current || 0).toString(),
    });

    const isMobile =
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      ) ||
      window.matchMedia('(max-width: 767px)').matches ||
      'ontouchstart' in window ||
      navigator.maxTouchPoints;

    const currentMeta = {
      id: meta?.id,
      role: meta?.role || 'USER',
      browser: meta?.browser || fnBrowserDetect(),
      system: meta?.system || detectMob(),
      ipAddress: meta?.ipAddress || location.host,
      language: meta?.language || navigator.language,
      platform: meta?.platform || (navigator as any)?.userAgentData?.platform,
      location: meta?.location || res?.location || '',
      time: meta?.time || Date.now(),
      isMobile: meta?.isMobile || isMobile,
      participant: {
        sid: getParticipant(embedId)?.sid,
        name: getParticipant(embedId)?.name,
        participantInfo: (getParticipant(embedId) as any).participantInfo,
        roomOptions: (getParticipant(embedId) as any)?.roomOptions,
      },
      name: meta?.name,
      email: meta?.email,
      phoneNumber: meta?.phoneNumber,
      image: meta?.image,
      userAgent: navigator.userAgent,
      appVersion: navigator.appVersion,
      screen: `${screen.width} x ${screen.height}`,
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      engine: getBrowserEngine(),
      window: `${window.innerWidth} x ${window.innerHeight}`,
    };

    const data = JSON.stringify(currentMeta);
    if (room.state !== 'disconnected' && isActive && account?.is_active) {
      await LiveKitService.updateParticipantMeta({
        roomName: room.name,
        participantIdentity: room.localParticipant.identity,
        metaData: encrypt(data, room.name),
      });
    }
    setMeta(encrypt(data, room.name));
  }, [
    room.localParticipant.identity,
    room.state,
    room.name,
    meta,
    getParticipant,
    isActive,
    account,
  ]);

  const fnBrowserDetect = () => {
    const userAgent = navigator.userAgent;
    if (/edg/i.test(userAgent)) return 'Edge';
    if (/chrome|chromium|crios/i.test(userAgent)) return 'Chrome';
    if (/firefox|fxios/i.test(userAgent)) return 'Firefox';
    if (/safari/i.test(userAgent)) return 'Safari';
    if (/opr\//i.test(userAgent)) return 'Opera';
    return 'No browser detection';
  };
  const detectMob = () => {
    const userAgent = navigator.userAgent;
    if (/Android/i.test(userAgent)) return 'Android';
    if (/BlackBerry/i.test(userAgent)) return 'BlackBerry';
    if (/iPhone|iPad|iPod/i.test(userAgent)) return 'iOS';
    if (/Opera Mini/i.test(userAgent)) return 'Opera';
    if (/IEMobile/i.test(userAgent) || /WPDesktop/i.test(userAgent))
      return 'Windows';
    if (/Macintosh/i.test(userAgent)) return 'Macintosh';
    return 'No OS detection';
  };

  useEffect(() => {
    if (error) {
      const timeout = setTimeout(() => {
        setError(undefined);
        setShowOverlay(false);
      }, 10000);
      return () => {
        clearTimeout(timeout);
      };
    }
  }, [error]);

  useEffect(() => {
    getLocation();
  }, [getLocation]);

  useEffect(() => {
    if (
      room.localParticipant.identity &&
      room['roomInfo']?.name &&
      room?.state === 'connected'
    ) {
      updateMetaData();
    }
  }, [
    room,
    updateMetaData,
    room.localParticipant.identity,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    room['roomInfo']?.name,
  ]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (room) {
        setActive(room.state === 'connected');
        if (room.state === 'connected') clearInterval(interval);
      }
    }, 1000);
    return () => {
      clearInterval(interval);
      if (callIntervalRef.current) clearInterval(callIntervalRef.current);
    };
  }, [room]);

  const joinRoom = async (getPrivateRoomId?: string) => {
    resetPublicRoom();
    const targetRoomId = privateRoomId || getPrivateRoomId;
    if (targetRoomId) {
      const res = await LiveKitService.getSession(
        targetRoomId,
        room.name?.split('_')[0]
      );
      if (res?.data?.status === 'COMPLETED') {
        sessionStorage.removeItem('privateRoomId');
        sessionStorage.removeItem('remoteControl');
        localStorage.removeItem('callStartTime');
        setPrivateRoomIdJoined(false);
        setActionType(undefined);
        setPrivateRoomId(undefined);
        return;
      }
    }
    if (room?.state === ConnectionState.Connected) {
      privateRoomjoinedRef.current = true;
      await room.disconnect();
    } else {
      setShowOverlay(true);
    }
    if (room?.state === 'disconnected' && targetRoomId) {
      sendMessage('Join');
      sessionStorage.setItem('privateRoomId', targetRoomId);
      const participantName =
        meta?.name ||
        meta?.id ||
        generateUniqueId({ length: 10, useLetters: true, useNumbers: false });

      try {
        const resData = await connectAgent({
          participantName,
          roomName: targetRoomId,
        });

        if (resData?.length && socketUrl) {
          privateRoomjoinedRef.current = true;
          setActionType(undefined);
          setPrivateToken(resData);
          setPrivateRoomIdJoined(true);
        }
      } catch (error) {
        console.error('Error connecting to LiveKit:', error);
      }
    }

    resetConnectionState();
    clearTimeout(timeoutRef.current);
  };

  useEffect(() => {
    console.info('privateToken value is changed', privateToken);
  }, [privateToken]);

  const disconnectRoom = async () => {
    if (room?.state === 'disconnected') {
      sessionStorage.removeItem('privateRoomId');
      sessionStorage.removeItem('remoteControl');
      localStorage.removeItem('callStartTime');
      setPrivateRoomIdJoined(false);
      privateRoomjoinedRef.current = false;
      setActionType(undefined);
      setPrivateRoomId(undefined);
      const uniqueSessionId = sessionStorage.getItem('uniqueSessionId');
      if (uniqueSessionId) {
        const res = await connectAgent({
          participantName: uniqueSessionId,
          roomName: room.name,
        });
        if (res?.length) {
          sessionStorage.removeItem('privateRoomId');
          sessionStorage.removeItem('remoteControl');
          localStorage.removeItem('callStartTime');
          await room.connect(REACT_APP_LIVEKIT_SOCKET_URL, res);
        }
      }
    }
  };

  const resetPublicRoom = () => {
    sessionStorage.removeItem('incomingSessionId');
    setIsCallActive(false);
    if (callIntervalRef.current) {
      clearInterval(callIntervalRef.current);
    }
    if (rejectTimeout) {
      clearInterval(rejectTimeout);
    }
    if (JoinTimeoutRef) {
      JoinTimeoutRef.current = undefined;
    }
    audioRef.current?.pause();
    noOfAgentsAttemptedRef.current = 0;
  };

  const handlePrivateRoomReconnection = () => {
    privateRoomRejoiningRef.current = true;
    setTogglePrivateRoom(false);
    // Re-show private roomm it after 1 second
    setTimeout(() => {
      setTogglePrivateRoom(true);
      privateRoomRejoiningRef.current = false;
    }, 1000);
  };

  let intervalId: NodeJS.Timeout | null = null;

  const checkInternetConnectivity = () => {
    console.info('Checking internet connectivity...');
    fetch('https://livekit.skygram.co/', {
      mode: 'no-cors',
    })
      .then(() => {
        console.info('CONNECTED TO INTERNET');

        if (intervalId) clearInterval(intervalId);

        if (
          room?.state === ConnectionState.Disconnected &&
          !roomRejoined.current
        ) {
          setIsOfflineStringPublicRoom('Please wait, Reconnecting to Room');
          disconnectRoom();
          roomRejoined.current = true;
        }
        setIsOfflineStringPublicRoom('');
      })
      .catch(() => {
        console.info('INTERNET CONNECTIVITY ISSUE');
      });
  };

  useEffect(() => {
    const handleOnline = () => {
      console.info('Network online, checking internet availability...');
      if (intervalId) clearInterval(intervalId);
      intervalId = setInterval(checkInternetConnectivity, 5000);
    };

    const handleOffline = () => {
      console.info('OFFLINE DETECTED');
      roomRejoined.current = false;
      setIsOfflineStringPublicRoom(
        'You are offline, check your internet connection'
      );
      if (intervalId) clearInterval(intervalId);
    };

    if (!privateRoomRejoiningRef) {
      window.addEventListener('offline', handleOffline);
      window.addEventListener('online', handleOnline);
    }

    return () => {
      if (intervalId) clearInterval(intervalId);
      window.removeEventListener('offline', handleOffline);
      window.removeEventListener('online', handleOnline);
    };
  }, []);

  useEffect(() => {
    if (!privateRoomRejoiningRef) {
      room?.on(RoomEvent.Reconnecting, () => {
        setIsOfflineStringPublicRoom(
          'You are offline, check your internet connection'
        );
      });

      room?.on(RoomEvent.Reconnected, () => {
        setIsOfflineStringPublicRoom('');
        roomRejoined.current = false;
      });

      room?.on(RoomEvent.Disconnected, (DisconnectReason) => {
        console.info('public room DisconnectReason', DisconnectReason);
        if (navigator.onLine && !intervalId && !privateRoomjoinedRef) {
          roomRejoined.current = false;
          intervalId = setInterval(checkInternetConnectivity, 5000);
        }
      });
    }

    return () => {};
  }, [room]);

  const ChatBotPopover = () => (
    <Container isPrivateRoomJoined={isPrivateRoomJoined}>
      {isOfflineStringPublicRoom ? (
        <PublicRoomContainer>
          <FlexColumnDiv>
            <ErrorText>{isOfflineStringPublicRoom}</ErrorText>
          </FlexColumnDiv>
        </PublicRoomContainer>
      ) : (
        <FlexColumnDiv>
          {isPrivateRoomJoined && togglePrivateRoom ? (
            <LiveKitRoom
              serverUrl={socketUrl}
              token={privateToken}
              connect={true}
              audio={false}
              video={false}
              data-lk-theme="default"
              style={{
                borderRadius: '8px',
                border: '2px solid #0d6efd',
              }}
            >
              <PrivateRoom
                publicRoom={room}
                meta={meta}
                setPrivateRoomIdJoined={(joined) => {
                  setPrivateRoomIdJoined(joined);
                  setTimeout(
                    () => draggablePopoverRef.current?.resetToAnchor(),
                    250
                  );
                }}
                setActionType={setActionType}
                updateMetaData={updateMetaData}
                setPrivateRoomId={setPrivateRoomId}
                setError={setError}
                account={account}
                onReconnection={handlePrivateRoomReconnection}
              />
            </LiveKitRoom>
          ) : privateRoomRejoiningRef.current ? (
            <PublicRoomContainer>
              {' '}
              <ErrorText>Reconneccting to meeting, please wait.</ErrorText>
            </PublicRoomContainer>
          ) : !(isActive && account?.is_active) ? (
            <PublicRoomContainer>
              {' '}
              <ErrorText>
                Something went wrong, please try after some time
              </ErrorText>
            </PublicRoomContainer>
          ) : (
            <PublicRoomContainer>
              <FlexColumnDiv>
                {!(
                  actionType !== undefined ||
                  !isConnectAgent ||
                  !!error?.length ||
                  isCallActive ||
                  room.state === ConnectionState.Disconnected
                ) && (
                  <FlexColumnDiv>
                    <AgentConnectTitle>
                      You are requesting to initiate a <br /> cobrowse session
                    </AgentConnectTitle>
                    {!(account?.meta?.end_user as any)?.botHide && (
                      <StyledButton
                        type="button"
                        variant={'primary'}
                        onClick={sendRequest}
                      >
                        {!isConnectAgent
                          ? 'connecting ... '
                          : 'Connect with agent'}
                      </StyledButton>
                    )}
                    <AgentConnectTitle>
                      By connecting, you agree to your <br /> tab being visible
                      to agent and this session <br /> recorded. See our privacy
                      policy for more.
                    </AgentConnectTitle>
                    <CobrowsingInfo>
                      <CobrowsingImage
                        src={
                          'https://skygram-utils.s3.us-west-004.backblazeb2.com/logo/sm-logo.png'
                        }
                        alt="logo"
                      />
                      <CobrowsingText>
                        Cobrowsing powered by Skygram
                      </CobrowsingText>
                    </CobrowsingInfo>
                  </FlexColumnDiv>
                )}
                {isCallActive && (
                  <FlexColumnDiv>
                    <ErrorText>Connecting...</ErrorText>
                    <StyledButton
                      type="button"
                      variant="danger"
                      onClick={() => endCall()}
                    >
                      <HiPhoneMissedCall />
                    </StyledButton>
                  </FlexColumnDiv>
                )}
                {error &&
                actionType !== 'Join' &&
                error?.includes('Thank you') ? (
                  <SuccessText>{error}</SuccessText>
                ) : (
                  error &&
                  actionType !== 'Join' && <ErrorText>{error}</ErrorText>
                )}

                {!isCallActive && actionType === 'Join' && (
                  <JoinContainer>
                    <FlexContainer>
                      <ColumnContainer>
                        <CallingHeader>
                          <Title>{senderName || 'Agent'}</Title>{' '}
                          <Subtitle>
                            is asking to initiate <br />a cobrowsing session and
                            speak with you
                          </Subtitle>
                        </CallingHeader>
                        <WarningText>
                          <MdOutlineTimer />{' '}
                          <CountDown
                            initialCountdown={
                              JoinTimeoutRef.current ||
                              REACT_APP_CALL_JOIN_TIMEOUT_SEC
                            }
                            onCountdownEnd={() => {
                              handleRejectRequest();
                              // Set action type to undefined
                            }}
                            interval={rejectTimeout}
                          />
                        </WarningText>
                        <ButtonWrapper>
                          <ActionButton
                            variant="danger"
                            onClick={() => {
                              audioRef.current?.pause();
                              handleRejectRequest();
                            }}
                          >
                            Decline
                          </ActionButton>
                          <ActionButton
                            variant="success"
                            onClick={() => {
                              audioRef.current?.pause();
                              joinRoom();
                            }}
                          >
                            Accept
                          </ActionButton>
                        </ButtonWrapper>
                        <Subtitle>
                          By connecting, you agree to your <br /> tab being
                          visible to agent and this session <br /> recorded. See
                          our privacy policy for more.
                        </Subtitle>
                        <LogoContainer>
                          <Logo
                            src="https://skygram-utils.s3.us-west-004.backblazeb2.com/logo/sm-logo.png"
                            alt="logo"
                          />
                          <LogoText>Cobrowsing powered by Skygram</LogoText>
                        </LogoContainer>
                      </ColumnContainer>
                    </FlexContainer>
                  </JoinContainer>
                )}
              </FlexColumnDiv>
            </PublicRoomContainer>
          )}
        </FlexColumnDiv>
      )}
    </Container>
  );

  const bgColor = () => {
    return room.state === ConnectionState.Disconnected && !isPrivateRoomJoined
      ? (account?.meta?.end_user as any)?.botInActivebgColor || '#f8f9fa'
      : isActive && account?.is_active
        ? (account?.meta?.end_user as any)?.botActivebgColor || '#2f55d4'
        : '';
  };

  return isActive ? (
    <ChatRequestContainer containerRef={containerDivRef}>
      <>
        <audio
          ref={audioRef}
          src={
            'https://skygram-utils.s3.us-west-004.backblazeb2.com/PhoneCallingSoundEffect.mp3'
          }
          preload="auto"
          crossOrigin="anonymous"
        />
        <ChatDraggablePopover
          draggablePopoverRef={draggablePopoverRef}
          isPrivateRoomJoined={isPrivateRoomJoined}
          showOverlay={showOverlay}
          floatingChatBtnRef={
            floatingChatBtnRef.current != null
              ? floatingChatBtnRef
              : containerDivRef
          }
        >
          <ChatBotPopover />
        </ChatDraggablePopover>
        {!(account?.meta?.end_user as any)?.botHide
          ? account?.is_active && (
              <FloatingChatButton
                ref={floatingChatBtnRef}
                isDisabled={isPrivateRoomJoined}
                onClick={() => {
                  disconnectRoom();
                  setShowOverlay(!showOverlay);
                }}
                bgColor={bgColor()}
                botIconUrl={
                  (account?.meta?.end_user as any)?.botIcon ||
                  'https://f004.backblazeb2.com/file/skygram-utils/logo/skygram-logo-white.png'
                }
              />
            )
          : ''}
      </>
    </ChatRequestContainer>
  ) : null;
}
