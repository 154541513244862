/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  CarouselLayout,
  ControlBar,
  ParticipantTile,
  ReceivedChatMessage,
  RoomAudioRenderer,
  useChat,
  useRemoteParticipants,
  useRoomContext,
  useTracks,
} from '@livekit/components-react';
import '@livekit/components-styles';
import { getRecordConsolePlugin } from '@rrweb/rrweb-plugin-console-record';
import { eventWithTime } from '@rrweb/types';
import {
  createEmbedService,
  SourceBuffer,
  TransportAckRecordEvent,
} from '@syncit/core';
import {
  ConnectionState,
  RemoteParticipant,
  Room,
  RoomEvent,
  Track,
} from 'livekit-client';
import { useCallback, useEffect, useRef, useState } from 'react';
import { BsChatLeftText } from 'react-icons/bs';
import { IoReloadCircleOutline } from 'react-icons/io5';
import { record } from 'rrweb';
import { bufferSec, REACT_APP_LIVEKIT_SOCKET_URL } from '../constant';
import { LiveKitService } from '../service';
import { MetaProps } from '../types';
import { connectAgent } from '../util';
import { IAccount } from '../util/interface/IAccount.interface';
import CanvasC, { CanvasCRef } from './Canvas';
import Chat from './chat';
import { LiveKitTransporter, TransporterEvents } from './LiveKitTransporter';
import { getRecordNetworkPlugin } from './networkLogger';
import {
  ErrorText,
  FlexColumnDiv,
  PublicRoomContainer,
} from './styleComponent/ChatRequest/ChatBotPopover';
import {
  AgentNameText,
  BlinkingIcon,
  CarouselContainer,
  CenteredText,
  ChatArea,
  ChatIcon,
  ContentArea,
  ControlBarContainer,
  FlexColumnContainerWrapper,
  Heading,
  InnerContent,
  LoadingText,
  MainContainer,
  StyledButton,
  SubTitle,
} from './styleComponent/ChatRequest/PrivateRoom';
import CallTimer from './TimerComponent';

export type PaintingConfig = {
  stroke: string;
  strokeWidth: number;
  mode: string;
};

export default function PrivateRoom({
  publicRoom,
  meta,
  setPrivateRoomIdJoined,
  setActionType,
  updateMetaData,
  setPrivateRoomId,
  setError,
  account,
  onReconnection,
}: {
  publicRoom: Room;
  meta?: MetaProps;
  setPrivateRoomIdJoined: (data: boolean) => void;
  setActionType: (data: undefined) => void;
  updateMetaData: () => void;
  setPrivateRoomId: (data: undefined) => void;
  setError: (message: string | undefined) => void;
  account: IAccount | undefined;
  onReconnection: any;
}) {
  const validControlStates = ['start', 'request', 'controlling'] as const;
  const storedState = sessionStorage?.getItem('remoteControl');
  const canvasRef = useRef<CanvasCRef>(null);
  const customCursor: any = document.getElementById('custom-cursor-sg');
  customCursor.style.top = '0px';
  customCursor.style.left = '0px';
  const chat = useChat();
  const [messageList, setMessageList] = useState<ReceivedChatMessage[]>([]);
  const room: Room = useRoomContext();
  const [isChatOpen, setIsChatOpen] = useState(false);
  const [updatedTime, setUpdatedTime] = useState<any>(null);
  const [transporter, setTransporter] = useState<LiveKitTransporter | null>(
    null
  );
  const [service, setService] = useState<ReturnType<
    typeof createEmbedService
  > | null>(null);
  const [, setControlService] = useState<
    'start' | 'request' | 'controlling' | null
  >(
    validControlStates.includes(storedState as any)
      ? (storedState as (typeof validControlStates)[number])
      : null
  );
  const [painting, setPainting] = useState<boolean>(false);
  const agentName = useRef<string | null>(null);
  const [networkLow, setNetworkLow] = useState<boolean>(false);
  const [isOfflineString, setIsOfflineString] = useState<string>('');
  const roomRejoined = useRef<boolean>(false);

  const tracks = useTracks(
    [
      { source: Track.Source.Camera, withPlaceholder: true },
      { source: Track.Source.ScreenShare, withPlaceholder: false },
    ],
    { onlySubscribed: false }
  );
  let buffer: SourceBuffer<eventWithTime>;
  if (room?.state === 'connected') {
    buffer = new SourceBuffer<eventWithTime>({
      bufferMs: bufferSec,
      onTimeout(record) {
        transporter?.sendRecord(record);
      },
    });
  }
  const remoteParticipants: RemoteParticipant[] = useRemoteParticipants({
    updateOnlyOn: [
      RoomEvent.ParticipantConnected,
      RoomEvent.ParticipantDisconnected,
      RoomEvent.Disconnected,
      RoomEvent.Reconnected,
      RoomEvent.ParticipantMetadataChanged,
      RoomEvent.ParticipantNameChanged,
    ],
  });

  useEffect(() => {
    const customDiv = document.createElement('div');
    const styleTag = document.createElement('style');
    styleTag.innerHTML = `
      body, html, a, input, textarea, select {
          cursor: none !important;
        }
    `;
    document.head.appendChild(styleTag);
    document.body.appendChild(styleTag);
    if (room?.localParticipant?.identity) {
      customDiv.className = 'custom-mouse-cursor-sg';
      const customSpan = document.createElement('span');
      customSpan.className = 'cursor-mouse-name-sg';
      customDiv.appendChild(customSpan);
      document.body.appendChild(customDiv);
      document.addEventListener('mousemove', (event) => {
        customDiv.style.top = `${event.pageY}px`;
        customDiv.style.left = `${event.pageX}px`;
        customSpan.textContent = `user_${room?.localParticipant?.identity}`;
        customSpan.style.color = 'red';
      });
    }

    return () => {
      if (customDiv.parentNode) {
        customDiv.parentNode.removeChild(customDiv);
        document.body.style.cursor = '';
      }
      if (styleTag.parentNode) {
        styleTag.parentNode.removeChild(styleTag);
      }
    };
  }, [room?.localParticipant?.identity]);

  const removeConnection = useCallback(() => {
    if (!service || !buffer) return;
    buffer.reset();
    transporter?.sendStop();
    stopService(service, 'STOP');
    resetState();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [service, transporter]);

  const initializeTransporter = useCallback(async () => {
    if (meta?.id) {
      const newTransporter = await setupTransporter(meta?.id);
      const newService = await setupService(newTransporter);
      if (room?.state === 'connected' && !transporter) {
        newTransporter.login();
        newTransporter.room = room;
        newTransporter.connected = true;
        record({
          emit(event: eventWithTime) {
            buffer.add(event);
          },
          plugins: [
            getRecordConsolePlugin({
              level: ['info', 'log', 'warn', 'error'],
              lengthThreshold: 100000,
              stringifyOptions: {
                numOfKeysLimit: 100000,
                depthOfLimit: 100000,
              },
              logger: window.console,
            }),
            getRecordNetworkPlugin(),
          ],
        });
        newTransporter.send({
          event: TransporterEvents.UserInit,
          payload: undefined,
        });
        if (newTransporter && newService) {
          await setupTransporterEvents(newTransporter, newService);
        }
        setService(newService);
        setTransporter(newTransporter);
        const _storedState = sessionStorage?.getItem('remoteControl');
        if (!_storedState) {
          remoteControl('start');
        }
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [meta?.id, room?.state]);

  async function setupTransporter(uid: string): Promise<LiveKitTransporter> {
    const transporter = await new LiveKitTransporter({ uid, room });
    return transporter;
  }

  function isAccountClassPresent(
    accountClassNames: any,
    selectedClassName: any
  ) {
    const accountClassArray = accountClassNames
      .split(',')
      .map((className: any) => className.trim().replace(/^\./, ''));
    const selectedClassArray = selectedClassName
      .split(' ')
      .map((className: any) => className.trim());
    return accountClassArray.some((accountClass: any) =>
      selectedClassArray.includes(accountClass)
    );
  }

  async function setupService(transporter: LiveKitTransporter) {
    const service = await createEmbedService({
      transporter,
      record,
      stopRecordFn: null,
      buffer,
    });
    service.start();
    service.subscribe();
    service.send('START');
    return service;
  }

  let currentCoordinates = { x: undefined, y: undefined };
  let currentElement: any = null;
  function setupTransporterEvents(
    transporter: LiveKitTransporter,
    service: any
  ) {
    transporter.on(TransporterEvents.MirrorReady, () => {
      transporter.sendSourceReady();
      customCursor.classList.add('custom-cursor-sg');
    });

    transporter.on(TransporterEvents.Start, () => {
      window.scrollTo(0, 0);
      console.info("service.send('CONNECT');");
      service.send('CONNECT');
    });

    transporter.on(TransporterEvents.AckRecord, ({ payload }) => {
      buffer.delete(payload as TransportAckRecordEvent['payload']);
    });

    transporter.on(TransporterEvents.RemoteControl, ({ payload }) => {
      handleRemoteControlEvent(payload);
    });

    transporter.on(TransporterEvents.Stop, async () => {
      await disconnectRoom();
      setError('Thank you! Call ended with agent. 👍');
    });
    // transporter.on(TransporterEvents.EndCall, async () => {
    //   await disconnectRoom();
    //   setError('Thank you! Call ended with agent. 👍');
    // });

    transporter.on(TransporterEvents.AgentTimer, ({ payload }: any) => {
      if (payload) {
        setUpdatedTime(payload);
      }
    });

    transporter.on(TransporterEvents.MouseClick, async ({ payload }: any) => {
      if (payload?.data?.x !== undefined && payload?.data?.y !== undefined) {
        currentCoordinates = { x: payload?.data?.x, y: payload?.data?.y };
        const element = document.elementFromPoint(
          payload?.data.x,
          payload?.data.y
        );
        if (currentElement?.id !== element?.id) {
          currentElement = element;
          if (element) {
            const event = new MouseEvent('click', {
              bubbles: true,
              cancelable: true,
              view: window,
            });
            const cursorElement = document.querySelector('.custom-cursor-sg');
            if (cursorElement) {
              cursorElement.classList.add('custom-cursor-sg-blink');
              setTimeout(() => {
                cursorElement.classList.remove('custom-cursor-sg-blink');
              }, 500);
            }
            if (
              element.tagName.toLowerCase() === 'input' ||
              element.tagName.toLowerCase() === 'select' ||
              element.tagName.toLowerCase() === 'textarea'
            ) {
              transporter.send({
                event: TransporterEvents.CustomInputField,
                payload: currentCoordinates,
              });
            }
            element.dispatchEvent(event);
          } else {
            console.error('No element found at the given coordinates.');
          }
        } else {
          console.error('same element selected');
        }
      }
    });
    transporter.on(TransporterEvents.ScrollEvent, async ({ payload }: any) => {
      window.scrollBy({
        top: payload.deltaY,
        left: payload.deltaX,
        behavior: 'smooth',
      });
    });

    transporter.on(
      TransporterEvents.CustomInputFieldValue,
      async ({ payload }: any) => {
        if (payload?.x !== undefined && payload?.y !== undefined) {
          if (
            currentCoordinates?.x === payload?.x &&
            currentCoordinates?.y === payload?.y
          ) {
            const element: any = document.elementFromPoint(
              payload?.x,
              payload?.y
            );
            if (element) {
              const isDisabled =
                element.disabled || element.getAttribute('readonly') !== null;

              if (isDisabled) {
                console.info(
                  'Element is disabled or readonly, skipping value update.'
                );
                return;
              }
              if (
                isAccountClassPresent(account?.mask_element, element?.className)
              ) {
                return;
              }
              if (
                element.tagName.toLowerCase() === 'input' ||
                element.tagName.toLowerCase() === 'textarea'
              ) {
                if (element.value) {
                  if (payload?.value === 'Backspace') {
                    const newStr = element.value.slice(0, -1);
                    element.value = newStr;
                  } else {
                    element.value += payload?.value;
                  }
                } else {
                  element.value =
                    payload?.value === 'Backspace' ? '' : payload?.value;
                }
                const inputEvent = new Event('input', {
                  bubbles: true,
                  cancelable: true,
                });
                element.dispatchEvent(inputEvent);
                const changeEvent = new Event('change', {
                  bubbles: true,
                  cancelable: true,
                });
                element.dispatchEvent(changeEvent);
              } else if (element.tagName.toLowerCase() === 'select') {
                const options = element.options;
                let currentIndex = element.selectedIndex;
                if (payload?.value === 'ArrowDown') {
                  currentIndex = Math.min(currentIndex + 1, options.length - 1);
                } else if (payload?.value === 'ArrowUp') {
                  currentIndex = Math.max(currentIndex - 1, 0);
                } else {
                  for (let i = 0; i < options.length; i++) {
                    if (
                      options[i].text
                        .toLowerCase()
                        .startsWith(payload?.value.toLowerCase())
                    ) {
                      currentIndex = i;
                      break;
                    }
                  }
                }
                element.selectedIndex = currentIndex;
                const optionElement = options[currentIndex];
                const clickEvent = new MouseEvent('click', {
                  bubbles: true,
                  cancelable: true,
                  view: window,
                });
                optionElement.dispatchEvent(clickEvent);
                const changeEvent = new Event('change', {
                  bubbles: true,
                  cancelable: true,
                });
                element.dispatchEvent(changeEvent);
              }
            } else {
              console.error(
                'No input, select, or textarea field found at the given coordinates.'
              );
            }
          }
        }
      }
    );

    transporter.on(TransporterEvents.AgentMouseEvent, handleMouseEvent);

    setupDrawingEvents(transporter);

    transporter.room.on(RoomEvent.ParticipantConnected, async () => {
      service.start();
      service.subscribe();
      const _storedState = sessionStorage?.getItem('remoteControl');
      if (!_storedState) {
        remoteControl('start');
      }
      transporter.send({
        event: TransporterEvents.ClientTimer,
        payload: localStorage.getItem('callStartTime'),
      });
    });
    transporter.on(TransporterEvents.Recording as any, () => {});
  }

  const remoteControl = (type: 'start' | 'request' | 'controlling' | null) => {
    if (type === 'start' || type === 'controlling') {
      transporter?.sendRemoteControl({ action: type });
    }
    setControlService(type);
    if (type) {
      sessionStorage.setItem('remoteControl', type);
    }
  };

  function handleRemoteControlEvent(payload: any) {
    const {
      action,
    }: {
      action: 'start' | 'request' | 'controlling' | null;
    } = payload as any;
    remoteControl(action);
  }

  function handleMouseEvent({ payload }: any) {
    payload?.data?.positions?.forEach((item: any) => {
      if (customCursor) {
        const scaledX = item.x + window.scrollX;
        const scaledY = item.y + window.scrollY;
        customCursor.style.left = `${scaledX}px`;
        customCursor.style.top = `${scaledY}px`;
      }
    });
  }

  function setupDrawingEvents(transporter: LiveKitTransporter) {
    transporter.on(TransporterEvents?.StartPaint as any, () =>
      setPainting(true)
    );
    transporter.on(TransporterEvents?.EndPaint as any, () =>
      setPainting(false)
    );

    transporter.on(TransporterEvents?.StartLine as any, (event) => {
      canvasRef.current?.startLine(event?.payload as { x: number; y: number });
    });

    transporter.on(TransporterEvents?.EndLine as any, () => {
      canvasRef.current?.endLine();
    });

    transporter.on(TransporterEvents?.ClearPaint as any, () => {
      canvasRef.current?.clearAllLines();
    });

    transporter.on(TransporterEvents?.DrawLine as any, (event) => {
      const points = (event.payload as any).points;
      canvasRef.current?.setPoints(
        points.map((point: number, index: number) => {
          return index % 2 === 0
            ? point / (event.payload as any)?.scaleValue + window.scrollX - 10
            : point / (event.payload as any)?.scaleValue + window.scrollY;
        })
      );
    });

    transporter.on(TransporterEvents?.Highlight as any, (event) => {
      canvasRef.current?.highlight(
        (event.payload as any).left,
        (event.payload as any).top
      );
    });
  }

  function stopService(service: any, eventType: string) {
    if (service?.state?.matches(eventType)) {
      service.send(eventType);
    }
    service?.stop();
  }

  function resetState() {
    setService(null);
    setTransporter(null);
    remoteControl(null);
  }

  const getSession = useCallback(async () => {
    if (customCursor && publicRoom?.name && room?.name) {
      const res = await LiveKitService.getSession(
        room.name,
        publicRoom.name.split('_')[0]
      );
      const agentFirstName = res?.agent_id?.first_name || 'Agent';
      const agentLastName = res?.agent_id?.last_name || '';

      const truncatedAccountName =
        account?.name && account.name.length > 15
          ? `${account.name.slice(0, 15)}...`
          : account?.name || '';

      const fullName =
        `${truncatedAccountName}.${agentFirstName} ${agentLastName}`.trim();

      const truncatedName =
        agentFirstName.length > 15
          ? `${truncatedAccountName}-${agentFirstName.slice(0, 15)}...`
          : truncatedAccountName + '_' + agentFirstName;

      agentName.current = fullName;
      updateCursorName(customCursor, truncatedName, 'black');
    } else if (customCursor && publicRoom?.name && room?.name === '') {
      updateCursorName(customCursor, 'Agent', 'black');
    }
  }, [customCursor, publicRoom?.name, room?.name, account]);

  const updateCursorName = (
    cursorElement: any,
    name: string,
    color: string
  ) => {
    const nameSpan = document.createElement('span');
    nameSpan.classList.add('cursor-name-sg');
    nameSpan.textContent = name;
    nameSpan.style.color = color;
    cursorElement.appendChild(nameSpan);
    cursorElement.style.setProperty('--cursor-name', `"${name}"`);
    cursorElement.style.setProperty('--cursor-color', color);
  };

  const cleanupSession = useCallback(() => {
    if (customCursor) {
      const nameSpans = customCursor.querySelectorAll('.cursor-name-sg');
      if (nameSpans) {
        nameSpans.forEach((nameSpan: any) => nameSpan.remove());
      }
      customCursor.classList.remove('custom-cursor-sg');
      customCursor.style.removeProperty('--cursor-name');
      customCursor.style.removeProperty('--cursor-color');
    }
  }, [customCursor]);

  useEffect(() => {
    if (customCursor) {
      getSession();
    }
  }, [customCursor, getSession]);

  useEffect(() => {
    if (!transporter && meta) {
      initializeTransporter();
    }
    return () => {
      removeConnection();
      cleanupSession();
    };
  }, [
    meta,
    room?.state,
    transporter,
    initializeTransporter,
    service,
    room,
    removeConnection,
    customCursor,
    cleanupSession,
  ]);

  const userLatitude = useRef<number | null>(null);
  const userLongitude = useRef<number | null>(null);

  const onGeoLocationSuccess = useCallback((position: GeolocationPosition) => {
    const { latitude, longitude } = position.coords;
    userLatitude.current = latitude;
    userLongitude.current = longitude;
  }, []);

  const onGeoLocationError = useCallback(() => {
    userLatitude.current = null;
    userLongitude.current = null;
  }, []);

  const getLocation = useCallback(() => {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition(
        onGeoLocationSuccess,
        onGeoLocationError
      );
    } else {
      console.error('Geo location not available');
    }
  }, [onGeoLocationError, onGeoLocationSuccess]);

  useEffect(() => {
    getLocation();
  }, [getLocation]);

  useEffect(() => {
    setTimeout(async () => {
      await LiveKitService.getMetaDetailsFormMachine({
        lat: (userLatitude.current || 0).toString(),
        lon: (userLongitude.current || 0).toString(),
      });
    }, 5000);
  }, []);

  const disconnectRoom = async () => {
    await removeConnection();
    await cleanupSession();
    setPrivateRoomIdJoined(false);
    setActionType(undefined);
    setPrivateRoomId(undefined);
    const uniqueSessionId = sessionStorage.getItem('uniqueSessionId');
    if (uniqueSessionId) {
      const res = await connectAgent({
        participantName: uniqueSessionId,
        roomName: publicRoom.name,
      });
      if (res?.length) {
        sessionStorage.removeItem('privateRoomId');
        sessionStorage.removeItem('remoteControl');
        localStorage.removeItem('callStartTime');
        await publicRoom.connect(REACT_APP_LIVEKIT_SOCKET_URL, res);
        updateMetaData();
      }
      await room.disconnect();
    }
  };
  useEffect(() => {
    if ('connection' in navigator) {
      const connection: any = navigator.connection;
      const updateConnectionStatus = () => {
        if (['2g', '3g', 'slow-2g'].includes(connection.effectiveType)) {
          console.warn('Network speed is very slow');
          setNetworkLow(true);
          if (transporter) {
            transporter.send({
              event: TransporterEvents.NetworkLow,
              payload: undefined,
            });
          }
        } else {
          setNetworkLow(false);
        }
      };

      connection.addEventListener('change', updateConnectionStatus);
      updateConnectionStatus();

      return () => {
        connection.removeEventListener('change', updateConnectionStatus);
      };
    }
  }, [transporter]);

  let intervalId: NodeJS.Timeout | null = null;

  const checkInternetConnectivity = () => {
    console.info('Checking internet connectivity...');
    fetch('https://livekit.skygram.co/', {
      mode: 'no-cors',
    })
      .then(() => {
        console.info('CONNECTED TO INTERNET');

        if (intervalId) clearInterval(intervalId);
        if (
          room?.state === ConnectionState.Disconnected &&
          !roomRejoined.current
        ) {
          setError(
            `You've disconnected from meeting due to network loss for long time. Thank you👍`
          );
          disconnectRoom();
          roomRejoined.current = true;
        }
        setIsOfflineString('');
      })
      .catch(() => {
        console.info('INTERNET CONNECTIVITY ISSUE');
      });
  };

  useEffect(() => {
    const handleOnline = () => {
      if (intervalId) clearInterval(intervalId);
      intervalId = setInterval(checkInternetConnectivity, 5000);
    };

    const handleOffline = () => {
      console.info('OFFLINE DETECTED');
      setIsOfflineString('You are offline, check your internet connection');
      if (intervalId) clearInterval(intervalId);
    };

    window.addEventListener('offline', handleOffline);
    window.addEventListener('online', handleOnline);

    return () => {
      if (intervalId) clearInterval(intervalId);
      window.removeEventListener('offline', handleOffline);
      window.removeEventListener('online', handleOnline);
    };
  }, [room?.state]);

  useEffect(() => {
    room?.on(RoomEvent.Reconnecting, () => {
      setIsOfflineString('You are offline, check your internet connection');
    });

    room?.on(RoomEvent.Reconnected, () => {
      setIsOfflineString('Please wait, Reconnecting to Room');
      removeConnection();
      cleanupSession();
      onReconnection();
    });

    return () => {};
  }, [room]);

  if (!room) {
    return null;
  }

  return (
    <>
      {isOfflineString ? (
        <PublicRoomContainer>
          <FlexColumnDiv>
            <ErrorText>{isOfflineString}</ErrorText>
          </FlexColumnDiv>
        </PublicRoomContainer>
      ) : (
        <MainContainer isChatOpen={isChatOpen}>
          <InnerContent>
            <ContentArea isChatOpen={isChatOpen}>
              <FlexColumnContainerWrapper>
                <>
                  <Heading>
                    <BlinkingIcon size={24} />
                    <SubTitle>Live Support</SubTitle>
                  </Heading>
                  {updatedTime && <CallTimer updatedTime={updatedTime} />}
                  {networkLow && (
                    <LoadingText>
                      <CenteredText>
                        <span>
                          Your internet speed is very slow, which might impact
                          co-browsing session.
                        </span>
                      </CenteredText>
                    </LoadingText>
                  )}
                  {room.remoteParticipants.size === 0 && (
                    <LoadingText>
                      <IoReloadCircleOutline size={20} />
                      <CenteredText>
                        <span>Waiting for &nbsp;</span>
                        <AgentNameText>
                          {(agentName?.current || 'Agent') + '...'}
                        </AgentNameText>
                      </CenteredText>
                    </LoadingText>
                  )}
                  {(room.localParticipant.isCameraEnabled ||
                    remoteParticipants[0]?.isCameraEnabled) && (
                    <CarouselContainer>
                      <CarouselLayout tracks={tracks} style={{ width: '100%' }}>
                        <ParticipantTile />
                      </CarouselLayout>
                    </CarouselContainer>
                  )}
                </>

                <ControlBarContainer>
                  <ChatIcon
                    isChatOpen={isChatOpen}
                    onClick={() => setIsChatOpen(!isChatOpen)}
                  >
                    <BsChatLeftText style={{ color: 'white' }} />
                    {chat.chatMessages?.length - messageList?.length > 0 && (
                      <span className="badge">
                        {chat.chatMessages?.length - messageList?.length}
                      </span>
                    )}
                  </ChatIcon>
                  <ControlBar
                    variation="minimal"
                    controls={{ screenShare: false, leave: false }}
                  />
                  <StyledButton
                    variant="danger"
                    onClick={() => {
                      // transporter?.send({
                      //   event: TransporterEvents.EndCall,
                      //   payload: undefined,
                      // });
                      setError(`You've left the meeting. Thank you👍`);
                      disconnectRoom();
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="none"
                    >
                      <path
                        fill="currentColor"
                        fillRule="evenodd"
                        d="M2 2.75A2.75 2.75 0 0 1 4.75 0h6.5A2.75 2.75 0 0 1 14 2.75v10.5A2.75 2.75 0 0 1 11.25 16h-6.5A2.75 2.75 0 0 1 2 13.25v-.5a.75.75 0 0 1 1.5 0v.5c0 .69.56 1.25 1.25 1.25h6.5c.69 0 1.25-.56 1.25-1.25V2.75c0-.69-.56-1.25-1.25-1.25h-6.5c-.69 0-1.25.56-1.25 1.25v.5a.75.75 0 0 1-1.5 0v-.5Z"
                        clipRule="evenodd"
                      ></path>
                      <path
                        fill="currentColor"
                        fillRule="evenodd"
                        d="M8.78 7.47a.75.75 0 0 1 0 1.06l-2.25 2.25a.75.75 0 1 1-1.06-1.06l.97-.97H1.75a.75.75 0 0 1 0-1.5h4.69l-.97-.97a.75.75 0 0 1 1.06-1.06l2.25 2.25Z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                  </StyledButton>

                  <RoomAudioRenderer />
                </ControlBarContainer>
              </FlexColumnContainerWrapper>
            </ContentArea>

            {painting && (
              <CanvasC
                ref={canvasRef}
                role="master"
                mode={'brush'}
                stroke={'#df4b26'}
                strokeWidth={5}
              />
            )}
          </InnerContent>
          <div>
            <ChatArea isChatOpen={isChatOpen}>
              {isChatOpen && (
                <Chat
                  chat={chat}
                  room={room}
                  messageList={messageList}
                  setMessageList={setMessageList}
                />
              )}
            </ChatArea>
          </div>
        </MainContainer>
      )}
    </>
  );
}
