import { ReactNode } from 'react';
import styled from 'styled-components';

const MessageContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  margin: 0 0.5rem;
  margin-bottom: 1rem;
`;

const StyledInput = styled.input`
  flex: 1;
  padding-right: 2.5rem;
  margin: 0 0.25rem;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  height: 38px;
  &:focus {
    outline: none;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  }
  width: 10px;
  background: #fff;
  color: black;
  cursor: text !important;
`;

const MessageInput = ({
  message,
  setMessage,
  sendMessage,
  children,
}: {
  message: string;
  setMessage: (data: string) => void;
  sendMessage: () => void;
  children: ReactNode;
}) => {
  return (
    <MessageContainer>
      <StyledInput
        type="text"
        placeholder="Enter message"
        value={message}
        onChange={(e) => setMessage(e.target.value)}
        onKeyDown={(e) => {
          if (e?.key === 'Enter') {
            sendMessage();
          }
        }}
      />
      {children}
    </MessageContainer>
  );
};

export default MessageInput;
