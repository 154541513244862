import Konva from 'konva';
import { forwardRef, useEffect, useImperativeHandle, useRef } from 'react';
import styled from 'styled-components';

class CanvasDrawing {
  stage: Konva.Stage;
  layer: Konva.Layer;
  isPainting: boolean;
  lastLine: Konva.Line[];
  resetTimeout: NodeJS.Timeout | null;

  constructor({ width, height }: { width: number; height: number }) {
    this.stage = new Konva.Stage({
      container: 'syncit-canvas',
      width,
      height,
    });

    this.layer = new Konva.Layer();
    this.stage.add(this.layer);

    this.isPainting = false;
    this.lastLine = []; // Track all lines
    this.resetTimeout = null;
  }

  startLine(
    stroke: string,
    strokeWidth: number,
    mode: string,
    role: string,
    pos: { x: number; y: number }
  ) {
    this.isPainting = true;
    const line = new Konva.Line({
      stroke,
      strokeWidth,
      globalCompositeOperation:
        mode === 'brush' ? 'source-over' : 'destination-out',
      points: pos ? [pos.x, pos.y] : [0, 0],
    });
    this.layer.add(line);
    this.lastLine.push(line);
    // this.resetPointsTimeout();
  }

  endLine() {
    this.isPainting = false;
  }

  draw() {
    if (!this.isPainting) return;

    const pos = this.stage.getPointerPosition();
    if (!pos) return;

    const lastLine = this.lastLine[this.lastLine.length - 1];
    const newPoints = lastLine.points().concat([pos.x, pos.y]);
    this.drawPoints(newPoints);
  }

  drawPoints(points: number[]) {
    if (!this.lastLine.length) {
      console.error('No line to draw on');
      return;
    }

    const lastLine = this.lastLine[this.lastLine.length - 1];
    lastLine.points(points);
    this.layer.batchDraw();
    // this.resetPointsTimeout();
  }

  // resetPointsTimeout() {
  //   if (this.resetTimeout) clearTimeout(this.resetTimeout);

  //   this.resetTimeout = setTimeout(() => {
  //     this.clearAllLines();
  //   }, 5000);
  // }

  clearAllLines() {
    this.lastLine.forEach((line) => line.destroy());
    this.lastLine = [];
    this.layer.batchDraw();
  }

  highlight(left: number, top: number, stroke: string, strokeWidth: number) {
    const highlightEl = document.createElement('div');
    Object.assign(highlightEl.style, {
      position: 'absolute',
      left: `${left}px`,
      top: `${top}px`,
      background: stroke,
      width: `${2 * strokeWidth}px`,
      height: `${2 * strokeWidth}px`,
      borderRadius: `${strokeWidth}px`,
      animation: 'syncit-highlight 1000ms ease-out',
    });
    document.body.appendChild(highlightEl);

    setTimeout(() => {
      document.body.removeChild(highlightEl);
    }, 1000);
  }
}

type CanvasCProps = {
  role?: string;
  mode: string;
  stroke: string;
  strokeWidth: number;
};

export type CanvasCRef = {
  startLine: (pos: { x: number; y: number }) => void;
  endLine: () => void;
  setPoints: (points: number[]) => void;
  highlight: (left: number, top: number) => void;
  clearAllLines: () => void;
};

const CanvasContainer = styled.div`
  position: fixed;
  inset: 0;
  z-index: 999;
`;

const CanvasC = forwardRef<CanvasCRef, CanvasCProps>(
  ({ stroke, strokeWidth, mode, role = 'master' }, ref) => {
    const canvasRef = useRef<HTMLDivElement | null>(null);
    const canvasInstance = useRef<CanvasDrawing | null>(null);

    useImperativeHandle(ref, () => ({
      startLine: (pos: { x: number; y: number }) => {
        canvasInstance.current?.startLine(stroke, strokeWidth, mode, role, pos);
      },
      endLine: () => {
        canvasInstance.current?.endLine();
      },
      setPoints: (points: number[]) => {
        if (canvasInstance.current) {
          canvasInstance.current.drawPoints(points);
        } else {
          console.error('canvasInstance.current is null');
        }
      },
      highlight: (left: number, top: number) => {
        canvasInstance.current?.highlight(left, top, stroke, strokeWidth);
      },
      clearAllLines: () => {
        canvasInstance.current?.clearAllLines();
      },
    }));

    useEffect(() => {
      if (canvasRef.current) {
        const { width, height } = canvasRef.current.getBoundingClientRect();
        canvasInstance.current = new CanvasDrawing({ width, height });
      } else {
        console.error('canvasRef.current is null in CanvasC');
      }
    }, []);

    return <CanvasContainer id="syncit-canvas" ref={canvasRef} />;
  }
);

CanvasC.displayName = 'CanvasC';

export default CanvasC;
