import { forwardRef, ReactNode } from 'react';
import styled from 'styled-components';

const ChatBodyElement = styled.div`
  background-color: white;
  min-height: 250px;
  max-height: 250px;
  max-width: 100vw;
  overflow-x: hidden;
  padding: 2px;
  scrollbar-width: thin;
  scrollbar-color: #90a4ae #cfd8dc;
  &::-webkit-scrollbar {
    scrollbar-width: thin;
  }
`;

const ChatBody = forwardRef<HTMLDivElement, { children: ReactNode }>(
  ({ children }, ref) => {
    return <ChatBodyElement ref={ref}>{children}</ChatBodyElement>;
  }
);

export default ChatBody;
