import styled from 'styled-components';

export const Container = styled.div<{ isPrivateRoomJoined: boolean }>`
  background-color: #f5f5f5;
  width: 100%;
  height: 100%;
  border-radius: ${({ isPrivateRoomJoined }) =>
    isPrivateRoomJoined ? '0' : '8px'};
  border: ${({ isPrivateRoomJoined }) =>
    isPrivateRoomJoined ? 'none' : '1px solid #0d6efd'};
`;

export const PublicRoomContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 280px;
`;
export const FlexColumnDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

export const ErrorText = styled.h5`
  color: #ff0000;
  text-align: center;
`;

export const SuccessText = styled.h5`
  color: #00ff00;
  text-align: center;
`;

export const StyledButton = styled.button<{
  variant: 'primary' | 'success' | 'danger';
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1rem;
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 4px;
  background-color: ${({ variant }) => {
    switch (variant) {
      case 'primary':
        return '#0d6efd';
      case 'success':
        return '#28a745';
      case 'danger':
        return '#dc3545';
      default:
        return '#007bff';
    }
  }};
  color: white;
  cursor: pointer;
  font-family: 'Fira Sans', sans-serif;
  font-size: 14px;

  &:hover {
    opacity: 0.8;
  }
`;

export const TimerText = styled.p`
  color: #ffc107;
  display: flex;
  align-items: center;
`;

export const CountdownContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const CobrowsingInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 0.5rem;
  margin-top: 1rem;
`;

export const CobrowsingImage = styled.img`
  width: 22px;
  height: 22px;
`;

export const CobrowsingText = styled.span`
  color: #a8a7a7;
  font-family: 'Fira Sans', sans-serif;
  margin-left: 0.5rem;
  font-size: 12px;
`;

export const AgentConnectTitle = styled.span`
  color: #a8a7a7;
  font-family: 'Fira Sans', sans-serif;
  font-size: 12px;
  text-align: center;
`;

export const ResizeIcon = styled.div`
  position: absolute;
  bottom: -10px;
  right: -10px;
  padding: 8px;
  cursor: nwse-resize;
  transform: rotate(90deg);
`;

export const JoinContainer = styled.div`
  margin: 1rem 0;
`;

export const FlexContainer = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
`;

export const ColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

export const CallingHeader = styled.div`
  text-align: center;
`;

export const Title = styled.span`
  color: black;
  font-size: 12px;
  font-family: 'Fira Sans', sans-serif;
  font-weight: bold;
  text-align: center;
`;

export const Subtitle = styled.span`
  color: black;
  font-size: 12px;
  font-family: 'Fira Sans', sans-serif;
  text-align: center;
`;

export const WarningText = styled.p`
  color: #ffc107; /* Adjust to match your theme */
  margin-bottom: 0;
  text-align: center;
`;

export const ButtonWrapper = styled.div`
  display: flex;
`;
export const ActionButton = styled(StyledButton)`
  flex: 1;
  margin: 0 0.5rem;
`;

export const LogoContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.25rem;
  margin-top: 0.25rem;
  border: 1px solid #ccc; /* Adjust color as needed */
  border-radius: 0.25rem;
  padding: 0.25rem;
`;

export const Logo = styled.img`
  width: 22px;
  height: 22px;
`;

export const LogoText = styled.span`
  color: black;
  font-size: 12px;
  font-family: 'Fira Sans', sans-serif;
  margin-left: 0.5rem;
`;
