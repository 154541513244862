/* eslint-disable @typescript-eslint/no-explicit-any */
import styled from 'styled-components';
import { DraggablePopover } from '../../DraggablePopover';
import { ReactNode } from 'react';

const StyledDraggablePopover = styled(DraggablePopover)`
  transform-origin: right bottom;
  z-index: 10000;
  min-width: 400px;
  max-width: 800px;
  max-height: 700px;
`;

export default function ChatDraggablePopover({
  draggablePopoverRef,
  isPrivateRoomJoined,
  showOverlay,
  floatingChatBtnRef,
  children,
}: {
  draggablePopoverRef: any;
  isPrivateRoomJoined: boolean;
  showOverlay: boolean;
  floatingChatBtnRef: any;
  children: ReactNode;
}) {
  return (
    <StyledDraggablePopover
      ref={draggablePopoverRef}
      resizable={isPrivateRoomJoined}
      open={showOverlay}
      anchorRef={floatingChatBtnRef}
      className="my-draggable"
    >
      {children}
    </StyledDraggablePopover>
  );
}
