/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Chunk,
  TransportSendRecordEvent,
  Transporter,
  TransporterEventHandler,
} from '@syncit/core';
import { RemoteParticipant, Room, RoomEvent } from 'livekit-client';
import { eventWithTime } from 'rrweb';

export enum TransporterEvents {
  SourceReady = 0,
  MirrorReady = 1,
  Start = 2,
  SendRecord = 3,
  AckRecord = 4,
  Stop = 5,
  RemoteControl = 6,
  AgentMouseEvent = 7,
  MouseSize = 8,
  StartPaint = 9,
  SetPaintingConfig = 10,
  StartLine = 11,
  DrawLine = 12,
  EndLine = 13,
  Highlight = 14,
  EndPaint = 15,
  ClearPaint = 16,
  MouseClick = 17,
  CustomInputField = 18,
  CustomInputFieldValue = 19,
  ScrollEvent = 20,
  Recording = 21,
  AgentTimer = 22,
  ClientTimer = 23,
  UserInit = 25,
  NetworkLow = 26,
  EndCall = 27,
}

export type LiveKitTransporterOptions = {
  uid: string;
  room: Room;
};
const sleep = (ms: number) => {
  new Promise((resolve) =>
    setTimeout(() => {
      resolve(undefined);
    }, ms)
  );
  return;
};

export class LiveKitTransporter implements Transporter {
  handlers: Record<TransporterEvents, Array<TransporterEventHandler>> = {
    [TransporterEvents.SourceReady]: [],
    [TransporterEvents.MirrorReady]: [],
    [TransporterEvents.Start]: [],
    [TransporterEvents.SendRecord]: [],
    [TransporterEvents.AckRecord]: [],
    [TransporterEvents.Stop]: [],
    [TransporterEvents.RemoteControl]: [],
    [TransporterEvents.AgentMouseEvent]: [],
    [TransporterEvents.MouseSize]: [],
    [TransporterEvents.StartPaint]: [],
    [TransporterEvents.SetPaintingConfig]: [],
    [TransporterEvents.StartLine]: [],
    [TransporterEvents.DrawLine]: [],
    [TransporterEvents.EndLine]: [],
    [TransporterEvents.Highlight]: [],
    [TransporterEvents.EndPaint]: [],
    [TransporterEvents.ClearPaint]: [],
    [TransporterEvents.MouseClick]: [],
    [TransporterEvents.CustomInputField]: [],
    [TransporterEvents.CustomInputFieldValue]: [],
    [TransporterEvents.ScrollEvent]: [],
    [TransporterEvents.Recording]: [],
    [TransporterEvents.AgentTimer]: [],
    [TransporterEvents.ClientTimer]: [],
    [TransporterEvents.UserInit]: [],
    [TransporterEvents.NetworkLow]: [],
    [TransporterEvents.EndCall]: [],
  };

  uid: string;
  role: string;
  room: Room;
  connected: boolean | undefined = false;
  fragmentPool: Record<string, string[]> = {};

  constructor(options: LiveKitTransporterOptions) {
    const { uid, room } = options;
    this.uid = uid;
    this.role = 'embed';
    this.room = room;
    this.room.on(RoomEvent.Connected, () => {
      this.connected = true;
      console.info('Self connected');
    });
    this.room.on(RoomEvent.Disconnected, () => {
      this.connected = false;
      console.info('Self Disconnected');
    });
    this.room.on(RoomEvent.Reconnected, () => {
      this.connected = true;
      console.info('Self Reconnected');
    });
    this.room.on(RoomEvent.Reconnecting, () => {
      // this.connected = true;
      console.info('Self Reconnecting');
    });

    this.room.on(RoomEvent.ParticipantConnected, this.onParticipantConnected);
    this.room.on(
      RoomEvent.ParticipantDisconnected,
      this.onParticipantDisconnected
    );
    this.room.on(RoomEvent.DataReceived, this.onDataReceived);
  }

  onParticipantConnected = (participant: RemoteParticipant) => {
    console.info('participant connected', participant.identity);
  };

  onParticipantDisconnected = (participant: RemoteParticipant) => {
    console.info('participant disconnected', participant.identity);
  };

  onDataReceived = async (payload: Uint8Array) => {
    if (!this.connected) {
      await sleep(50);
    }
    const messageText = new TextDecoder().decode(payload);

    let data: TransportSendRecordEvent;
    try {
      data = JSON.parse(messageText);
      this.handlers[data.event as TransporterEvents].forEach((h) =>
        h({
          event: data.event,
          payload: data.payload,
        })
      );
    } catch (_) {
      const matchedArr = messageText
        .toString()
        .match(/(\d+)\/(\d+)\/(\d+)_(.+)?/);

      if (!matchedArr) {
        return;
      }

      const [, current, total, id, raw] = matchedArr;

      if (!this.fragmentPool[id]) {
        this.fragmentPool[id] = [];
      }

      this.fragmentPool[id][parseInt(current, 10)] = raw;
      let complete = true;
      let concatRaw = '';

      for (let i = 1; i <= parseInt(total, 10); i++) {
        if (typeof this.fragmentPool[id][i] !== 'string') {
          complete = false;
        } else {
          concatRaw += this.fragmentPool[id][i];
        }
      }
      if (complete && concatRaw) {
        try {
          data = JSON.parse(concatRaw);
          this.handlers[data.event as TransporterEvents].forEach((h) => {
            return h({
              event: data.event,
              payload: data.payload,
            });
          });
        } catch (error) {
          window.location.reload();
          console.error('test', error);
        }
      }
    }
  };

  async send<T>(data: T) {
    if (!this.connected) {
      await sleep(50);
    }
    const packet = {
      payload: JSON.stringify(data),
    };
    if (this.room.remoteParticipants.size > 0) {
      this.room.localParticipant.publishData(
        new TextEncoder().encode(packet.payload),
        {
          reliable: true,
        }
      );
    }
  }

  async login(): Promise<boolean> {
    if (!this.connected) {
      await sleep(50);
    }
    return Promise.resolve(true);
  }

  sendSourceReady(data?: any) {
    console.info('send source ready', {
      event: TransporterEvents.SourceReady,
      payload: data,
    });

    return this.send({
      event: TransporterEvents.SourceReady,
      payload: data,
    });
  }

  sendMirrorReady() {
    return this.send({
      event: TransporterEvents.MirrorReady,
    });
  }

  sendStart() {
    return this.send({
      event: TransporterEvents.Start,
    });
  }

  stringify(obj: any): string {
    let cache: any[] | null = [];
    const str: string = JSON.stringify(obj, (key, value) => {
      if (typeof value === 'object' && value !== null) {
        if (cache!.indexOf(value) !== -1) {
          return; // Circular reference found, discard key
        }
        cache!.push(value); // Store value in cache
      }
      return value;
    });
    cache = null; // Enable garbage collection
    return str;
  }

  async sendRecord(record: Chunk<eventWithTime>) {
    if (!this.connected) {
      await sleep(50);
    }
    const encoder = new TextEncoder();
    const texts =
      JSON.stringify({
        event: TransporterEvents.SendRecord,
        payload: record,
      }).match(/(.|[\r\n]){1,31000}/g) || [];
    await Promise.all(
      texts.map(async (text: string, idx: number) => {
        const encodedData = encoder.encode(
          `${idx + 1}/${texts.length}/${record.id}_${text}`
        );
        if (this.room.state === 'connected') {
          this.room.localParticipant.publishData(encodedData, {
            reliable: true,
          });
        }
      })
    );
  }

  async ackRecord(id: number) {
    if (!this.connected) {
      await sleep(50);
    }
    return this.send({
      event: TransporterEvents.AckRecord,
      payload: id,
    });
  }

  sendStop() {
    return this.send({
      event: TransporterEvents.Stop,
    });
  }

  async sendRemoteControl(payload: any) {
    if (!this.connected) {
      await sleep(50);
    }
    return this.send({
      event: TransporterEvents.RemoteControl,
      payload,
    });
  }

  dispose() {
    this.room.disconnect();
    this.connected = false;
    console.info('Disconnected from LiveKit room');
  }

  on(event: TransporterEvents, handler: TransporterEventHandler) {
    this.handlers[event].push(handler);
  }
}
